import gql from 'graphql-tag'

export const ME_QUERY = gql`
  query {
    me {
      id
      firstName
      lastName
      email
    }
  }
`

export const ECHO_QUERY = gql`
  query {
    echo
  }
`

export const GET_PROJECT_QUERY = gql`
  query GetProject($id: ID!) {
    project(id: $id) {
      id
      projectType
      title
      recipientName
      recipientEmail
      deadline
      completed
      introVideoUrl
      shareUrl
      slug
      numCompleted
      numInvited
      user {
        id
        firstName
      }
      asks {
        id
        text
        order
        createdAt
      }
    }
  }
`

export const GET_PROJECT_W_PARTICIPANTS_QUERY = gql`
  query GetProjectParticipants($id: ID!) {
    project(id: $id) {
      id
      projectType
      title
      recipientName
      recipientEmail
      deadline
      completed
      introVideoUrl
      shareUrl
      slug
      numCompleted
      numInvited
      user {
        id
        firstName
      }
      asks {
        id
        text
        order
        createdAt
      }
      welcome {
        subject
        body
      }
      participants {
        name
        email
      }
    }
  }
`

export const GET_PROJECT_BY_SLUG_QUERY = gql`
  query GetProjectWithSlug($slug: String!) {
    projectWithSlug(slug: $slug) {
      title
      recipientName
      introVideoUrl
      shareUrl
      slug
      user {
        firstName
      }
      asks {
        id
        text
      }
    }
  }
`

export const GET_PROJECTS_QUERY = gql`
  query  {
    projects {
      projectType
      id
      title
      recipientName
      recipientEmail
      deadline
      completed
      introVideoUrl
      shareUrl
      thumbnailUrl
      slug
      numCompleted
      numInvited
      user {
        id
        firstName
      }
      asks {
        id
        text
        order
        createdAt
      }
    }
  }
`

export const GET_VIDEO_BY_ASK_QUERY = gql`
  query ($askId: ID) {
    video (askId: $askId) {
      id
      videoUrl
    }
  }
`

export const GET_ASKS_QUERY = gql`
  query ($projectId: ID!) {
    asks (projectId: $projectId) {
      id
      text
      projectId
      order
    }
  }
`

export const GET_CONTACTS_QUERY = gql`
  query ($searchText: String, $authCode: String) {
    contacts(searchText: $searchText, authCode: $authCode, participantSlug: null) {
      firstName
      lastName
      email
      birthdate
    }
  }
`

export const GET_CONTACTS_FROM_SLUG_QUERY = gql`
  query ($searchText: String, $authCode: String, $participantSlug: String) {
    contacts(searchText: $searchText, authCode: $authCode, participantSlug: $participantSlug) {
      firstName
      lastName
      email
    }
  }
`

export const GET_CONTACT_WITH_SLUG_QUERY = gql`
  query ($slug: String!) {
    contactWithSlug(slug: $slug) {
      firstName
      lastName
      email
      participator {
        __typename
        ... on User {
          __typename
          firstName
          name
        }
        ... on Participant {
          __typename
          firstName
          name
        }
      }

      birthdate
    }
  }
`

export const GET_BIRTHDAY_REMINDERS_QUERY = gql`
  query  {
    birthdayReminders {
      id
      firstName
      lastName
      email
      birthdate
      reminderActive
    }
  }
`


export const GET_UNSUBSCRIBE_GROUPS_QUERY = gql`
  query {
    unsubscribeGroups {
      id
      name
      description
    }
  }
`

export const PLAY_PROJECT_QUERY = gql`
  query ($slug: String!) {
    playProject (slug: $slug) {
      projectId
      recipientName
      showAsk
      videoUrls
      audioUrl
      isOwner
      videos {
        id
        videoUrl
        ask {
          text
        }
      }
    }
  }
`

export const GET_INVITED_PEOPLE_QUERY = gql`
  query ($projectId: ID!) {
    participants (projectId: $projectId) {
      name
      email
      uploaded
    }
  }
`

export const GET_TWILIO_ACCESS_TOKEN = gql`
  query ($participantSlug: String) {
    twilioAccessToken (participantSlug: $participantSlug) {
      roomName
      accessToken
    }
  }
`

export const GET_CURRENT_USER_TWILIO_ACCESS_TOKEN = gql`
  query {
    twilioAccessToken (participantSlug: null) {
      roomName
      accessToken
    }
  }
`

export const GET_CURRENT_USER_TWILIO_ROOM_RECORDINGS = gql`
  query ($roomSid: String!, $participantSid: String!) {
    twilioRecording (roomSid: $roomSid, participantSid: $participantSid, participantSlug: null) {
      id
      compositionUrl
      videoUrl
      audioUrl
    }
  }
`

export const GET_TWILIO_ROOM_RECORDINGS = gql`
  query ($roomSid: String!, $participantSid: String!, $participantSlug: String) {
    twilioRecording (roomSid: $roomSid, participantSid: $participantSid, participantSlug: $participantSlug) {
      id
      compositionUrl
      videoUrl
      audioUrl
    }
  }
`

export const GET_PARTICIPANT_FROM_SLUG_QUERY = gql`
  query ($slug: String!) {
    participant (slug: $slug) {
      id
      projectId
      name
      email
      hasToken
      videos {
        id
        askId
      }
      project {
        id
        projectType
        title
        slug
        recipientName
        introVideoUrl
        canInvite
        shareUrl
        user {
          firstName
        }
        asks {
          id
          text
          order
          createdAt
        }
      }
    }
  }
`

export const GET_SLACK_CHANNELS_QUERY = gql`
  query ($authenticationToken: String!) {
    slackChannels(authenticationToken: $authenticationToken) {
      id
      name
      purpose
      numMembers
    }
  }
`

export const TEAM_WELCOME_VIDEO_QUERY = gql`
  query ($authenticationToken: String!, $slackIds: [String!]) {
    teamWelcomeVideo(authenticationToken: $authenticationToken, slackIds: $slackIds) {
      videoUrls
      audioUrl
    }
  }
`