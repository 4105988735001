<template lang="pug">
div.view_container(v-bind:class="{small: isSmallScreen}")
  Header(v-on:is-small-screen="setIsSmallScreen")
  v-dialog
  div.view_title Send a personal note to people you invited
  div.description This will help you get videos faster.
  div.note
    div.subject
      span.bold Subject:&nbsp;&nbsp;&nbsp;
      span {{welcomeSubject}}
      br
    div.body
      span.bold Body
      br
      br
      span(v-html="welcomeBodyUpper")
      div.input_container
        textarea(v-model="personalNote" type="text" placeholder="Type in a personal note (optional)" ref="name")
      span(v-html="welcomeBodyLower")
  div.controls
    button.cta(v-on:click="next") Send
</template>

<script>
/* global gtag */
import { SEND_PERSONAL_INVITE_MUTATION } from '../graphql/mutations'
import { GET_PROJECT_W_PARTICIPANTS_QUERY } from '../graphql/queries'

import Header from '../components/Header'

export default {
  name: 'SendWelcome',
  props: {
    project_id: {
      type: String,
      default: null
    },
  },
  components: {
    Header,
  },
  watch: {
    project: function() {
      this.$store.commit('setProject',this.project)
    },
  },
  computed: {
    loadingSrc () {
      return process.env.BASE_URL + "loading_dark.gif"
    },
    isSignedIn () {
      return this.$store.getters.signedIn
    },
    currentProjectId () {
      if (this.project_id) return this.project_id
      return this.currentProject?.id
    },
    currentProject () {
      if (this.project) return this.project
      return this.$store.state.currentProject
    },
    welcomeSubject () {
      if (!this.currentProject && !this.currentProject.welcome) return ""
      return this.currentProject?.welcome?.subject
    },
    welcomeBody () {
      if (!this.currentProject && !this.currentProject.welcome) return ""
      return this.currentProject?.welcome?.body.replace(/\n/g,"<br>")
    },
    welcomeBodyUpper () {
      if (!this.welcomeBody) return ""
      // !FIXME !HACK: this notes about the message template content and tags
      return this.welcomeBody.split("{{note}}")[0]
    },
    welcomeBodyLower () {
      if (!this.welcomeBody) return ""
      // !FIXME !HACK: this notes about the message template content and tags
      return this.welcomeBody.split("{{note}}")[1]
    },
  },
  data () {
    return {
      personalNote: null,
      isSmallScreen: false,
      project: null,
    }
  },
  apollo: {
    project () {
      return {
        // gql query
        query: GET_PROJECT_W_PARTICIPANTS_QUERY,
        // Static parameters
        variables () {
          return {
            id: this.currentProjectId
          }
        },
        update: data => {
          if (!data.project && this.isSignedIn) this.noProjectError()
          return data.project
        },
        error: (error) => {
          console.log(error.message)
        },
        skip () {
          return !this.currentProjectId
        },
        fetchPolicy: 'network-only',
      }
    },
  },
  methods: {
    setIsSmallScreen: function(isSmall) {
      this.isSmallScreen = isSmall
    },
    next: function() {
      this.sendPersonalInvite()
    },
    sendPersonalInvite: async function() {
      this.loading = true
      this.$apollo.mutate({
        mutation: SEND_PERSONAL_INVITE_MUTATION,
        variables: {
          projectId: this.currentProjectId,
          participantSlug: null,
          note: this.personalNote,
        },
      }).then((data) => {
        const success = data.data.sendPersonalInvite.success
        this.loading = false
        if (success) this.$router.push({name:"Thanks"})
      }).catch((error) => {
        // console.error(error)
        this.loading = false
        this.errorToast(error)
      })
    },
    noProjectError: function () {
      this.$toast.error("Unable to find video project. Click here to close this and we'll take you back to home.", {
        timeout: null,
        onClose: () => this.$router.push({name:"Home"})
      })
    },
  },
  mounted: function () {
    window.vue = this
    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/send_welcome'})

    if (!this.isSignedIn) {
      this.$toast.error("Please sign in first. Click here to close this and we'll take you to the sign in page.",{
        timeout: null,
        onClose: () => this.$router.push({
          name:"LoginWithNext",
          params:  {
            next: "/send/invite/"+this.currentProjectId
          }
        })
      })
    } else if (!this.currentProject && !this.project_id) {
      this.noProjectError()
    }
  }
}
</script>

<style scoped>

.view_container {
  font-family: gibsonregular;
  font-size: 20px;
}

.description {
  margin-bottom: 20px;
}

.bold {
  font-family: gibsonsemibold;
}

.controls {
  margin: 20px 0px;
}

.note {
  margin: 2% 0;
  padding: 3% 2%;
  border: 6px solid #f6f6f7;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;

  /* box-shadow: 4px 4px 12px 12px rgba(0,0,0,0.04);
  -webkit-box-shadow: 4px 4px 12px 12px rgba(0,0,0,0.04);
  -moz-box-shadow: 4px 4px 12px 12px rgba(0,0,0,0.04); */

  overflow-x:hidden;
  overflow-y: auto;
}

.subject, .body {
  margin-bottom: 20px;
}


</style>
